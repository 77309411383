import React from 'react';
// import logo from './../logo.svg';
import profile from './../profile.jpg';
import facebookIcon from './../icons/facebook.svg';
import githubIcon from './../icons/github.svg';
import linkedinIcon from './../icons/linkedin.svg';
import emailIcon from './../icons/email.svg';

export default function Header() {
  return (
    <div className="user">
        <img src={profile} id="profile-image" alt="profile" />
        <h1>Sabin Maharjan</h1>
        <h2>Sr. Software Engineer</h2>
        <h3>Works at&nbsp;
          <a href="https://www.linkedin.com/company/logicabeans/" className="link">Logicabeans</a>
        </h3> 
        <h6>
          <a href="https://github.com/sabinm677" className="link">
            <img src={githubIcon} style={{ height: '15px' }} alt="github" /> 
          </a>
          <span className="separator"></span>
          <a href="https://www.linkedin.com/in/sabin-maharjan-25a4122a/" className="link">
            <img src={linkedinIcon} style={{ height: '15px' }} alt="linkedin" />
          </a>
          <span className="separator"></span>
          <a href="https://www.facebook.com/sabinmaharjan4" className="link">
            <img src={facebookIcon} style={{ height: '15px' }} alt="facebook" />
          </a>
          <span className="separator"></span>
          <a href="mailto:hello@sabin.xyz" className="link">
            <img src={emailIcon} style={{ height: '15px' }} alt="email" />
          </a>
        </h6>
    </div>
  );
};
